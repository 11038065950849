import { cva } from '@mentimeter/ragnar-tailwind-config';

export const actionVariants = cva(
  [
    'w-full',
    'items-stretch',
    'bg',
    'rounded',
    'border-2',
    'border-solid',
    'border-default',
    'select-none',
    'text-left',
    'pointer-events-auto',
    'transition-all ease-in delay-100',
    'hover:border-primary',
    'focus:shadow-[0_0_0_3px_rgba(var(--color-primary),0.4)]',
    'active:shadow-[0_0_0_3px_rgba(var(--color-primary),0.4)]',
    'disabled:opacity-60',
    'disabled:pointer-events-none',
  ],
  {
    variants: {
      border: {
        danger: ['border-negative', 'hover:border-negative'],
        active: ['border-primary'],
      },
      hidden: {
        true: ['border-dashed', 'hover:border-default'],
        false: [],
      },
    },
    defaultVariants: {
      hidden: false,
    },
  },
);
