/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import type { VirtualElement } from '@popperjs/core';
import { LinkIcon } from '@mentimeter/ragnar-visuals';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import type { SimpleInlineNodesT } from '@mentimeter/ragnar-markdown';
import { getInlineNodeSyntax } from '@mentimeter/ragnar-markdown';
import getCaretCoordinates from 'textarea-caret';
import type { ActionT } from '../action';
import { Action } from '../action';
import { Box } from '../box';
import { Text } from '../text';
import { Placement } from '../placement';

interface PropsT {
  text: string;
  forwardedRef: any; // weak typing due to forwardedRef typing issue
  options?: Array<SimpleInlineNodesT> | undefined;
  onUpdate?: ((event: any) => void) | undefined;
  track?: undefined | ((type: string) => void);
}

export const MarkdownMenu = ({
  text,
  forwardedRef,
  onUpdate,
  options = ['strong', 'emphasis', 'delete', 'link'],
  track,
}: PropsT) => {
  const [selectionStart, setSelectionStart] = React.useState(0);
  const [selectionEnd, setSelectionEnd] = React.useState(0);

  // splits text into what comes before the selection, the selection itself and what comes after
  const splitText = React.useMemo(() => {
    const before = text.slice(0, selectionStart);
    const selection = text.slice(selectionStart, selectionEnd);
    const after = text.slice(selectionEnd);
    return [before, selection, after] as const;
  }, [text, selectionStart, selectionEnd]);

  const virtualElement = React.useMemo<VirtualElement | null>(() => {
    if (forwardedRef && forwardedRef.current !== null) {
      // get position of selection within input
      const textFieldScrollY = forwardedRef.current.scrollTop;
      const textFieldScrollX = forwardedRef.current.scrollLeft;
      const selectionStartPos = getCaretCoordinates(
        forwardedRef.current,
        selectionStart,
      );
      const selectionEndPos = getCaretCoordinates(
        forwardedRef.current,
        selectionEnd,
      );

      // for input elements getCaretCoordinates gets the y-Pos slightly wrong.
      const heightCorrection =
        forwardedRef.current.tagName === 'INPUT' ? -8 : 0;

      // create virtual element at position of selection
      return {
        getBoundingClientRect: () => {
          // get position of input
          const inputPos = forwardedRef.current.getBoundingClientRect();

          const left =
            inputPos.left + selectionStartPos.left - textFieldScrollX;
          const top =
            inputPos.top +
            selectionStartPos.top +
            heightCorrection -
            textFieldScrollY;

          const rect = {
            width: selectionEndPos.left - selectionStartPos.left,
            height:
              selectionEndPos.top +
              selectionEndPos.height -
              selectionStartPos.top,
            top,
            right: inputPos.left + selectionEndPos.left - textFieldScrollX,
            bottom:
              inputPos.top +
              selectionEndPos.top +
              selectionEndPos.height +
              heightCorrection -
              textFieldScrollY,
            left,
            x: left,
            y: top,
          };
          return {
            ...rect,
            toJSON: () => rect,
          };
        },
      };
    } else return null;
  }, [forwardedRef, selectionStart, selectionEnd]);

  // menu is shown when user selects text
  const showMenu = React.useMemo(() => {
    return selectionStart !== selectionEnd;
  }, [selectionStart, selectionEnd]);

  // update selection start and end whenever selection changes
  React.useEffect(() => {
    const ref = forwardedRef.current;
    const updateSelection = () => {
      setSelectionStart(ref.selectionStart);
      setSelectionEnd(ref.selectionEnd);
    };
    document.addEventListener('select', updateSelection);
    // selection needs to be updated when the it is removed so the menu disappears
    // (which means on every click or input while the menu is shown)
    const updateSelectionWhenShown = () => {
      if (showMenu) {
        setSelectionStart(ref.selectionStart);
        setSelectionEnd(ref.selectionEnd);
      }
    };
    ref.addEventListener('input', updateSelectionWhenShown);
    ref.addEventListener('click', updateSelectionWhenShown);
    return () => {
      document.removeEventListener('select', updateSelection);
      ref.removeEventListener('input', updateSelectionWhenShown);
      ref.removeEventListener('click', updateSelectionWhenShown);
    };
  }, [forwardedRef, showMenu]);

  // updates the text input's text
  const updateText = (text: string) => {
    if (forwardedRef && forwardedRef.current) {
      forwardedRef.current.value = text;
      const event = document.createEvent('HTMLEvents');
      event.initEvent('change', false, true);
      forwardedRef.current.dispatchEvent(event);
      if (onUpdate) {
        onUpdate(event);
      }
    }
  };

  // adds markdown to text
  const applyMarkdown = (type: SimpleInlineNodesT) => {
    const [bef, sel, aft] = splitText;
    const token = getInlineNodeSyntax(type);
    const modifiedSelection = token[0] + sel + token[1];
    const newText = bef.concat(modifiedSelection, aft);
    updateText(newText);
    if (track) track(type);
    if (forwardedRef && forwardedRef.current) {
      forwardedRef.current.focus();
      // timeout seems to be necessary for selection to occur after focus
      setTimeout(() => {
        const newStart =
          typeof selectionStart === 'number'
            ? selectionStart + token[0].length
            : 0;
        const newEnd =
          typeof selectionEnd === 'number' ? selectionEnd + token[0].length : 0;

        forwardedRef.current.setSelectionRange(newStart, newEnd);
        // setSelectionRange does not trigger the select event (which would update the state) in safari,
        // so state needs to be updated here
        setSelectionStart(newStart);
        setSelectionEnd(newEnd);
      }, 0);
    }
  };

  return (
    showMenu &&
    virtualElement && (
      <Placement
        referenceId={virtualElement}
        placement="top"
        showArrow
        className="bg [&>.popper--arrow]:before:bg"
        popperStrategy="fixed"
      >
        <Box
          className={clsx([
            'px-1',
            'flex-row',
            'items-stretch',
            'rounded-lg',
            'bg',
            'shadow-[0_2px_8px_var(--palette-black-alpha-400)]',
          ])}
        >
          {options.map((node, i) => (
            <Box key={node} className="flex-row items-stretch">
              {i !== 0 && (
                <Box className="w-[1px] my-1 bg-[--color-border-weak]" />
              )}

              <Item onClick={() => applyMarkdown(node)}>
                {node === 'strong' && (
                  <Text className="text-125 text-center text font-bold">B</Text>
                )}
                {node === 'emphasis' && (
                  <Text className="text-125 text-center text italic">I</Text>
                )}
                {node === 'delete' && (
                  <Text className="text-125 text-center text line-through">
                    S
                  </Text>
                )}
                {node === 'code' && (
                  <Text className="text-125 text-center text font-mono">C</Text>
                )}
                {node === 'link' && (
                  <Box className="justify-center items-center">
                    <LinkIcon color="text" />
                  </Box>
                )}
              </Item>
            </Box>
          ))}
        </Box>
      </Placement>
    )
  );
};

const Item = ({ onClick, children }: ActionT) => {
  return (
    <Action
      onClick={onClick}
      className="w-[25px] justify-center border-none"
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onFocus={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </Action>
  );
};
