import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { useRagnar } from '@mentimeter/ragnar-react';
import type { ClickableT } from '../clickable';
import { Clickable } from '../clickable';
import { actionVariants } from './Action.variants';

export interface ActionT extends ClickableT {
  activeStateStyle?: 'outline' | 'filledIn';
  // States
  active?: boolean | undefined;
  disabled?: boolean | undefined;
  danger?: boolean | undefined;
  hidden?: boolean | undefined;
}

export const Action = React.forwardRef(
  (
    {
      disabled,
      danger,
      active = false,
      activeStateStyle = 'outline',
      hidden,
      borderColor,
      className,
      ...props
    }: ActionT,
    ref,
  ) => {
    const { theme } = useRagnar();
    const useFelaBorderColor = !danger && !active && borderColor;
    const useFelaBorderHoverColor = !danger && !hidden && borderColor;

    const defaultClasses = actionVariants({
      border: danger ? 'danger' : active ? 'active' : undefined,
      hidden,
    });

    return (
      <Clickable
        ref={ref}
        disabled={disabled}
        className={clsx(
          defaultClasses,
          activeStateStyle === 'filledIn' && active && 'bg-primary',
          useFelaBorderColor && 'border-[--action-border-color]',
          useFelaBorderHoverColor &&
            'hover:border-[--action-border-hover-color]',
          className,
        )}
        style={
          {
            '--action-border-color':
              theme.colors[borderColor as keyof typeof theme.colors] ||
              borderColor,
            '--action-border-hover-color':
              theme.colors[borderColor as keyof typeof theme.colors] ||
              borderColor,
          } as React.CSSProperties
        }
        {...props}
      />
    );
  },
);

Action.displayName = 'Action';
